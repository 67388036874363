.center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.contact-container {
    width: 70%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.header-class {
    text-align: center;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form input, .contact-form textarea {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    outline: none;
}

.contact-form button {
    background-color: #f8a51b;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #f78d0b;
}

.social-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.social-icon {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    transition: transform 0.3s;
}

.social-icon:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 960px) {
    .contact-container {
        width: 90%;  /* Override the width for mobile */
        padding: 10px;
    }
    .social-buttons {
        display: flex;
        justify-content: center;
        align-items: center; /* Center items vertically */
        margin-top: 20px;
        flex-wrap: wrap; /* Allow items to wrap onto the next line if there's not enough space */
    }

    .social-icon {
        width: 30px;
        height: 30px;
        margin: 5px 10px; /* Add a bit of vertical margin for spacing between wrapped items */
        transition: transform 0.3s;
    }
}
