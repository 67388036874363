

.skills-page {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 80vh; /* setting the height to 80% of viewport height */
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
}

.content-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(255, 254, 242);
  width: 50%;
}

.skill-stickers-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between stickers */
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 380px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.skill-stickers-container > * {
  transform: translateY(20px); /* Start a bit below their final position */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Fade-in and move up transition */
}

.skills-text h1 {
  color: var(--neutrals-800, #25272A);
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 60px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  margin-bottom: 20px; /* added margin to create space below the heading */
  margin-top: 10px;
}

.skills-text p {
  text-align: justify; /* Full justification of the text */
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 5px;
  margin-top: 20px;
  hyphens: auto; /* Helps with breaking words to give a more even justification */
  padding: 20px;
}


.three-cube-class {
  width: 50%;
  background-color:rgb(38, 38, 38);
}

.color-buttons-container {
  display: grid; 
  grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
  gap: 1rem;
  justify-items: center; /* center items horizontally */
}

.color-buttons-container button {
  border-radius: 10px;
  width: calc(15vw - 1rem); /* Adjusting for the gap */
  max-width: 120px; /* Ensuring it doesn't get too big on larger screens */
  height: calc(15vw - 1rem); /* Adjusting for the gap */
  max-height: 120px; /* Ensuring it doesn't get too big on larger screens */
  border: none;
  background-color: black;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-buttons-container button::before {
  content: "";
  width: calc(10vw - 1rem); /* Adjusting for the gap and making it smaller than the button */
  max-width: 100px; /* Ensuring it doesn't get too big on larger screens */
  height: calc(10vw - 1rem); /* Adjusting for the gap and making it smaller than the button */
  max-height: 100px; /* Ensuring it doesn't get too big on larger screens */
  border-radius: 10px;
  position: absolute;
}

/* [existing button color styles] */


.color-buttons-container .white-button::before {
  background-color: #FFFFFF;
}

.color-buttons-container .green-button::before {
  background-color: #009b48;
}

.color-buttons-container .blue-button::before {
  background-color: #2962ff;
}

.color-buttons-container .yellow-button::before {
  background-color: #FFBE00;
}

.color-buttons-container .red-button::before {
  background-color: #D50000;
}

.color-buttons-container .orange-button::before {
  background-color: #FF7900;
}

/* Mobile responsive design */
@media (max-width: 960px) {
  .skills-page {
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;
      margin: 17% auto;
  }

  .content-left, .three-cube-class {
      width: 100%;
  }

  .skills-text h1 {
    font-size: 40px;
    font-weight: 700;
    margin: 0%;
  }
  
  .skills-text p {
    font-size: 15px;
    padding-top: 0%;
    padding-bottom: 0%;
  }

  .color-buttons-container {
    display: flex; /* Change grid to flex for mobile */
    flex-wrap: nowrap; /* Prevent wrapping */
    justify-content: space-between; /* Evenly space the buttons */
    gap: 0; /* Remove any gaps */
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  .color-buttons-container button {
    width: 30px;
    height: 30px;
    border-radius: 3px;
  }
  
  .color-buttons-container button::before {
    border-radius: 1.5px;
    content: "";
    width: 25px;
    max-width: 100px; 
    height: 25px;
  }

  .three-cube-class {
    height: 350px;
    background-color:rgb(38, 38, 38);
  }

  .skill-stickers-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; /* Space between stickers */
    justify-content: center;
    align-items: center;
   padding-bottom: 30px;
  }
}
