.project-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 568px;
  /* New properties */
  border-radius: 10px;
  border: 1px solid var(--neutrals-100, #EBEDF0);
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(199, 199, 199, 0.08), 0px 3px 3px 0px rgba(199, 199, 199, 0.07), 0px 6px 4px 0px rgba(199, 199, 199, 0.04), 0px 11px 4px 0px rgba(199, 199, 199, 0.01), 0px 17px 5px 0px rgba(199, 199, 199, 0.00);

}

.project-card-image {
  width: 100%;
  height: auto;
  padding: 24px;
}

.project-card-content {
  padding: 16px;
}

.project-card-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
}

.project-card-description {
  margin: 8px 0;
  color: #757575;
}

.project-card-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.project-card-technology {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;
}

.tech-icon {
  border-radius: 50%;
}
