/* GridTillesBackground.css */

.gridTillesBackground-container {
    position: fixed;
    /* Make it span the entire viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: -1;
    overflow: hidden;
  }