/* Add this to your global CSS file, or at the top of your LandingPage.css file */

/* LandingPage.css */
.landing-page-container {
  margin-top: 3%;
  width: 100%; /* Take up 90% of the viewport width */
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  position: relative; /* establish new positioning context */
  padding-bottom: 1px; /* prevent unwanted scroll */
}
