header {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center; /* To center the navbar */
    padding: 10px;
    width: 100%;
    z-index: 1;
}

.gradient-nav {
    background: linear-gradient(to right, #000, #333);
    border-radius: 10px;
    padding: 10px 20px; /* To provide some spacing inside the navbar */
    display: inline-block; /* So it only takes as much space as it needs */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

nav ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    margin: 0 5px; /* Adjust margin between items */
}

nav ul li a {
    display: inline-block;
    color: #D7D7D7;
    font-size: 17px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 25px; /* Increased to make it more rounded */
    transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
    text-decoration: none;
}

nav ul li a:hover {
    transform: scale(1.05); /* Subtle scale on hover */
}
/* Active tab styles */
nav ul li a.active {
    color: #011526;
    background-color: #FFBE00;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px; /* Making it more squared */
}

/* Different active colors based on your design */
nav ul li:nth-child(2) a.active {
    background-color: #34D399; /* Green */
    border-radius: 5px; /* Making it more squared */
}

nav ul li:nth-child(3) a.active {
    background-color: #3B82F6; /* Blue */
    border-radius: 5px; /* Making it more squared */
}

nav ul li:nth-child(4) a.active {
    background-color: #DC2626; /* Red */
    border-radius: 5px; /* Making it more squared */
}

.contact-button {
    display: flex;
    align-items: center; /* Vertically align content in the middle */
    justify-content: center; /* Horizontally align content in the center */
    background-color: #333;
    color: #D7D7D7;
    text-decoration: none;
    width: 150px;
    font-size: 17px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 10px; 
}

.contact-button .svg-icon {
    margin-left: 10px;
}

.contact-button:hover {
    transform: scale(1.05); /* Subtle scale on hover */
    transform-origin: top right;
}

.separate-contact {
    right: 0px;
    top: 0px;
}

.contact-container-cube {
    position: absolute;
    right: 20px;
    top: 20px; 
    transition: opacity 0.3s ease;
}

@media only screen and (max-width: 960px) {
    .gradient-nav {
        padding: 5px 10px;
        width: 100%;  /* Ensure the nav takes full width */
    }

    nav ul {
        justify-content: space-between; /* Distribute items evenly */
    }

    nav ul li {
        width: 20%; /* Distribute the space equally among menu items */
        margin: 0; /* Remove margin between items */
        text-align: center; /* Center the items */
    }

    nav ul li a {
        font-size: 12px; /* Reduce font size for mobile */
        padding: 5px 5px; /* Reduce padding */
    }

    .contact-button {
        display: flex;
        align-items: center; /* Vertically align items */
        justify-content: center; /* Horizontally center items */
        font-size: 12px; /* Reduce font size for mobile */
        padding: 5px 5px; /* Reduce padding for mobile */
        width: 40px;
        margin-left: 20px;
    }

    .contact-button .svg-icon {
        display: flex;
        align-items: center; /* Vertically align the SVG within its container */
        justify-content: center; /* Horizontally center the SVG within its container */
    }

    .contact-button .svg-icon img {
        width: 14px; /* Reduce the size of the icon */
        height: auto; /* Keep the aspect ratio */
        margin-right: 10px;
    }

    .separate-contact {
        display: none;
    }
}