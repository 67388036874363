.skill-sticker {
    max-width: 144px;  /* 180 * 0.8 */
    max-height: 86.4px;  /* 108 * 0.8 */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7.2px;  /* 9 * 0.8 */
    border-radius: 7.2px;  /* 9 * 0.8 */
    border: 1px solid var(--neutrals-100, #EBEDF0);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(199, 199, 199, 0.08), 0px 3px 3px 0px rgba(199, 199, 199, 0.07), 0px 6px 4px 0px rgba(199, 199, 199, 0.04), 0px 11px 4px 0px rgba(199, 199, 199, 0.01), 0px 17px 5px 0px rgba(199, 199, 199, 0.00);
}

.skill-sticker-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14.4px;  /* 18 * 0.8 */
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 7.2%;  /* 9% * 0.8 */
}

.skill-logo {
    width: 36px;  /* 45 * 0.8 */
    height: 36px;  /* 45 * 0.8 */
}

.header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center; 
    margin-bottom: 7.2px;  /* 9 * 0.8 */
}

.skill-bar {
    width: 64.8px;  /* 81 * 0.8 */
    height: 21.6px;  /* 27 * 0.8 */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7.2px;  /* 9 * 0.8 */
    background-color: black;
    border-radius: 3.6px;  /* 4.5 * 0.8 */
}

.skill-level {
    width: 14.4px;  /* 18 * 0.8 */
    height: 14.4px;  /* 18 * 0.8 */
    border-radius: 3.6px;  /* 4.5 * 0.8 */
    margin: 0 3.6px;  /* 4.5 * 0.8 */
    transition: background-color 0.3s;
}


@media (max-width: 960px) {
    .skill-sticker {
        max-width: 80px; /* reduced by half */
        max-height: 49px; /* reduced by half */
        padding: 4px; /* reduced by half */
        border-radius: 4px; /* reduced by half */
    }
    
    .skill-sticker-text {
        font-size: 8px; /* reduced by half */
        padding-left: 4%; /* reduced by half */
    }
    
    .skill-logo {
        width: 17.5px; /* reduced by half */
        height: 17.5px; /* reduced by half */
        object-fit: contain; /* This ensures the image maintains its proportions */
        display: block;
    }
    
    .header {
        margin-bottom: 4px; /* reduced by half */
    }
    
    .skill-bar {
        width: 36.5px; /* reduced by half */
        height: 12px; /* reduced by half */
        margin-top: 4px; /* reduced by half */
        border-radius: 2.025px; /* reduced by half */
    }
    
    .skill-level {
        width: 8px; /* reduced by half */
        height: 8px; /* reduced by half */
        border-radius: 2.025px; /* reduced by half */
        margin: 0 2.025px; /* reduced by half */
    }
    
}
