.social-button {
    display: flex;
    align-items: center;
    height: 40px;
    border: none;
    background: #E0E0E0; /* Sample background color; adjust as needed */
    padding: 0 15px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px; 
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.social-button:hover {
    transform: translateY(-2px); 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.social-button img {
    width: 20px; 
    height: 20px; 
    margin-right: 10px;
}


.social-button span {
    font-size: 16px;
    color: var(--neutrals-800, #25272A);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 32px;
}

/* Styling for the anchor */
a.social-button-anchor {
    text-decoration: none;
}
