/* ProjectsPage.css */

.projects-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centers items horizontally */
    gap: 16px; /* Gap between cards */
    padding: 16px; /* Padding around the grid */
    max-height: 90vh; /* Adjust based on your requirements */
    margin-top: 6%;
    padding-bottom: 20px;
}
