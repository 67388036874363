.top-landing-page {
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
}

.text-section {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10%;
  margin-top: 10%;
}

.text-section h2 {
  color: var(--neutrals-800, #25272A);
  text-align: left;
  font-size: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
}

.text-section h1 {
  color: var(--neutrals-800, #25272A);
  text-align: left;
  font-size: 80px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
}

.text-section h3 {
  color: var(--neutrals-800, #25272A);
  text-align: left;
  font-size: 25px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 32px;
}


.empty-section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 5%;
}

.empty-section img {
  width: 70%;
  height: auto;
}

.social-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 30px;
}

.social-buttons button {
  width: 70px;
  height: 70px;
  border: none;
  background: #6b7884;
  color: #D7D7D7;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-buttons button:hover {
  transform: scale(1.22);
}

.social-buttons button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lower-part {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensures items stretch to the same height */
  margin: 1% auto; /* This centers the .lower-part horizontally */
  max-width: 1200px; /* Adjust this value as needed */
}

.education-card {
  flex: 1; /* Take up the full space available */
  margin-left: 5%; /* Or any gap you'd like between the two sections */
}

.right-section {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Gap between ProjectCard and LanguagesCard */
  flex: 1; /* Take up the full space available */
}


/* ... existing styles ... */

@media only screen and (max-width: 960px) {
  .top-landing-page {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .empty-section {
    margin-top: 8%;
  }

  .text-section {
    flex-basis: 100%;
    padding: 5% 5%;
    justify-content: center;
    align-items: center;
  }

  .text-group {
    text-align: center;
  }

  .text-section h2 {
    font-size: 25px;
  }

  .text-section h1 {
    font-size: 45px;
  }

  .text-section h3 {
    font-size: 20px;
  }

  .empty-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }

  .empty-section img {
    width: 100%;
    height: auto;
  }

  .social-buttons {
    justify-content: center;
    gap: 15px;
    margin-top: 25px;
  }

  .social-buttons button {
    width: 50px;
    height: 50px;
  }

  .lower-part {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    max-width: 100%; /* Take full available width */
    padding: 0 10px; /* Add some padding to left and right */
  }

  .education-card {
    margin-bottom: 16px; /* Add space between this and the next component */
    width: 100%; /* Take full available width */
    margin-left: 0; /* Or any gap you'd like between the two sections */
  }

  .right-section {
    margin-bottom: 16px; /* Add space between this and the next component */
  }

}
