.playground-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0%;
    padding-bottom: 0%;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
    font-family: 'Source Sans Pro', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.playground-container h2 {
    text-align: center;
    margin-top: 100px;
    /* Adjust this value to move the title as required */
    margin-bottom: 25px;
}

.playground-container h3 {
    text-align: center;
    margin-bottom: 50px;
}

/* This creates a container with a 16:9 aspect ratio */
.canvas-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: #D7D7D7;
    margin: 0 auto;
    /* Center the canvas horizontally */
}

.canvas-and-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center the direct children */


    border: 1px solid #e0e0e0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 80%;
    /* New properties */
    border-radius: 10px;
    border: 1px solid var(--neutrals-100, #EBEDF0);
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(199, 199, 199, 0.08), 0px 3px 3px 0px rgba(199, 199, 199, 0.07), 0px 6px 4px 0px rgba(199, 199, 199, 0.04), 0px 11px 4px 0px rgba(199, 199, 199, 0.01), 0px 17px 5px 0px rgba(199, 199, 199, 0.00);

}

.control-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    /* Space between canvas and buttons */
    align-self: flex-start;
    /* This overrides the parent's align-items and aligns the buttons to the left */
}

.control-buttons button {
    background: transparent;
    border: none;
    color: var(--neutrals-800, #25272A);
    font-size: 17px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 50px;
    transition: transform 0.3s ease;
    cursor: pointer;
    text-decoration: none;
}


.control-buttons button:first-child {
    background: #FFBE00;
    /* Only the first button has the yellow background */
    color: var(--neutrals-800, #25272A);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    /* Added a subtle shadow for depth */
}

.control-buttons button:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 960px) {
    .canvas-and-controls {
        max-width: 100%;
        border-radius: 0;
    }

    .playground-container{
        padding-left: 5%;
        padding-right: 5%;
    }
}